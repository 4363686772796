import Modules from "./modules";

/**
 * Class TwoColumnContent
 */
class TwoColumnContent {
    /**
     * Class constructor
     * @param {HTMLElement} module
     */
    constructor(module) {}

    /**
     * Returns the class name
     * @return {string}
     */
    static getName() {
      return "js-two_column_content";
    }
}

new Modules(TwoColumnContent);